<template>
  <div class="dialog">
    <div class="dialog-container">
      <el-form
        ref="checkForm"
        :model="ruleForm"
        :rules="rules"
        class="rule-form"
        label-width="135px"
      >
        <div class="dialog-box">
          <h3 class="db-head">寄件信息</h3>
          <div class="flex">
            <el-form-item class="flex-1" label="运单编号" prop="waybill_num">
              <el-input
                v-model="ruleForm.waybill_num"
                disabled
                placeholder="请输入运单编号"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <div class="flex-1"></div>
            <div class="flex-1"></div>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="仓库名称" prop="warehouseName">
              <el-input
                v-model="ruleForm.warehouseName"
                placeholder="请输入仓库名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="货品总件数"
              prop="totalCount"
            >
              <el-input
                v-model.number="ruleForm.totalCount"
                placeholder="请输入货品总件数"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="送货日期" prop="deliveryDate">
                <el-date-picker style="width:200px;"
                    v-model="ruleForm.deliveryDate"
                    type="datetime"
                    placeholder="请选择送货日期"
                >
                </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="报关方式" prop="cebxsi">
              <el-select v-model="ruleForm.cebxsi" placeholder="请选择报关方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'自理报关'},{id:1,name:'直接报关'},{id:2,name:'间接报关'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="出口城市" prop="exportCity">
              <el-input
                v-model="ruleForm.exportCity"
                placeholder="请输入出口城市"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="拼单" prop="ifAcceptOrder">
              <el-checkbox v-model="ruleForm.ifAcceptOrder" label="我接受拼单"></el-checkbox>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件（配货）方式"
              prop="mailingMethod"
            >
              <el-select v-model="ruleForm.mailingMethod" placeholder="请选择寄件（配货）方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:1,name:'上门提货'},{id:2,name:'自己送货'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="运输方式" prop="transportCategory">
              <el-radio-group v-model="ruleForm.transportCategory">
                <el-radio :label="0">城配</el-radio>
                <el-radio :label="1">物流</el-radio>
            </el-radio-group>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <div class="flex">
            <el-form-item class="flex-1" label="付款方式" prop="paymentMethod">
              <el-select v-model="ruleForm.paymentMethod" placeholder="请选择付款方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'月结'},{id:1,name:'现付'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="备注" prop="remark">
              <el-input type="textarea"
                v-model="ruleForm.remark"
                placeholder="请输入备注"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <h3 class="db-head">交货方式</h3>
          <div class="flex">
            <el-form-item class="flex-1" label="安装方式" prop="installationMethod">
              <el-select v-model="ruleForm.installationMethod" placeholder="请选择安装方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'自己安装'},{id:1,name:'预约安装'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item class="flex-1" label="送货方式" prop="deliveryMethod">
              <el-select v-model="ruleForm.deliveryMethod" placeholder="请选择送货方式" style="width:200px;">
                <el-option
                    v-for="item in [{id:0,name:'无'},{id:1,name:'自提'},{id:2,name:'上门'}]"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            </el-form-item>
            <div class="flex-1"></div>
          </div>
          <h3 class="db-head">收件人信息</h3>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="收件公司名称"
              prop="customerName"
            >
              <el-input
                v-model="ruleForm.customerName"
                placeholder="请输入收件公司名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="收件地址(目的地)"
              prop="customerAddress"
            >
              <el-input
                v-model="ruleForm.customerAddress"
                placeholder="请输入收件地址(目的地)"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="联系人姓名"
              prop="customerContacts"
            >
              <el-input
                v-model="ruleForm.customerContacts"
                placeholder="请输入联系人姓名"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="联系电话" prop="customerContactsPhone">
              <el-input
                v-model="ruleForm.customerContactsPhone"
                placeholder="请输入联系电话"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <h3 class="db-head">寄件人信息</h3>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件公司名称"
              prop="enterpriseName"
            >
              <el-input
                v-model="ruleForm.enterpriseName"
                placeholder="请输入寄件公司名称"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex-1" label="提货地址" prop="enterpriseAdress">
              <el-input
                v-model="ruleForm.enterpriseAdress"
                placeholder="请输入提货地址"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              class="flex-1"
              label="寄件负责人"
              prop="enterpriseDirector"
            >
              <el-input
                v-model="ruleForm.enterpriseDirector"
                placeholder="请输入"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="flex-1"
              label="负责人电话"
              prop="enterpriseDirectorMobile"
            >
              <el-input
                v-model="ruleForm.enterpriseDirectorMobile"
                placeholder="请输入"
                style="width: 200px"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div
      class="drawer-footer flex flex-justify-between"
      style="width: 400px; margin: 0 auto"
    >
      <el-button @click="hide">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { createWaybill, getWaybill, queryOrderWaybill } from "@/service/sale"

export default {
  components: {},
  model: {
    prop: "form",
    event: "change",
  },
  props: ["form"],
  data() {
    return {
      rules: {
        warehouseName: [{ required: "true", message: "请选择仓库名称" }],
        totalCount: [{ required: "true", message: "请输入货品总计数" }],
        deliveryDate: [{ required: "true", message: "请输入送货日期" }],
        mailingMethod: [{ required: "true", message: "请选择寄件（配货）方式" }],
        transportCategory: [{ required: "true", message: "请输入运输方式" }],
        installationMethod: [{ required: "true", message: "请选择安装方式" }],
        customerName: [{ required: "true", message: "请输入收件公司名称" }],
        customerAddress: [{ required: "true", message: "请输入收件地址(目的地)" }],
        customerContacts: [{ required: "true", message: "请输入联系人姓名" }],
        customerContactsPhone: [{ required: "true", message: "请输入联系电话" }],
        enterpriseName: [{ required: "true", message: "请输入寄件公司名称" }],
        enterpriseAdress: [{ required: "true", message: "请输入提货地址" }],
        enterpriseDirector: [{ required: "true", message: "请输入寄件负责人" }],
        enterpriseDirectorMobile: [{ required: "true", message: "请输入负责人电话" }],
      },
      ruleForm: {
        waybill_num: "",
        warehouseName: "", //仓库名称
        totalCount: "", //货品总件数
        cebxsi: 0, //报关方式
        exportCity: "", //出口城市
        deliveryDate: "", //送货日期
        mailingMethod: 1, // 寄件方式（1为上门提货，2为自己送货）
        transportCategory: 1, // 配送方式（0为城配，1为1物流）
        ifAcceptOrder: 0, // 是否接受拼单（0为否，1为是）
        paymentMethod: 0, // 付款方式（0为月结，1为现付）
        remark: "", //备注（限200字）
        installationMethod: 0, // 安装方式（0为自己安装，1为预约安装）
        deliveryMethod: 0, // （0是无，1是自提，2为上门）// 送货方式
        customerName: "", // 收件名称
        customerAddress: "", // 收件人地址
        customerContactsPhone: "", // 收件人联系电话
        customerContacts: "", // 收件人姓名
        enterpriseName: "", // 寄件名称
        enterpriseAdress: "", // 寄件地址
        enterpriseDirector: "", // 寄件人名称
        enterpriseDirectorMobile: "", // 寄件人联系方式
        refUrl: "", //反推地址
        orgId: 1,
        TenantId: 0,
        details: [
          {
            goodsCode: "10002-222-33-4", //物品类别Code
            goodsName: "床", //物品名称
            specifications: "2200x2000", //规格型号
            unit: "", //计量单位
            goodsType: "大床", //型号
            color: "白色", //颜色
            size: "", //尺寸（长宽高）组合
            number: 1000, //数量
          },
        ],
      },
    };
  },
  watch: {
    form: {
      handler() {
        if (this.form) {
            this.ruleForm = this.form;
        }
      },
      deep: true,
    },
  },
  created() {
      if (this.form) {
          this.ruleForm = this.form;
      }
    console.log("form", this.form);
  },
  methods: {
    onChange() {
      this.$emit("change", this.ruleForm);
    },
    handleChange(e) {
      console.log("e", e);
    },
    hide() {
      this.$emit("hide");
    },
    submitForm() {
      this.$refs.checkForm.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    submit() {
        console.log("ruleForm", this.ruleForm);
        createWaybill(this.ruleForm).then(rst => {
            this.$emit("success");
            this.hide();
            this.$message.success("保存成功");
        }).catch(err => {
            this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="less">
.dialog-box {
  width: 94%;
  margin: 0 auto;
  .db-head {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    border-radius: 2px;
    padding: 0px 3px;
    border-bottom: 3px solid rgb(75, 128, 239);
  }
}
</style>