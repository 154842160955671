<!-- 采购订单 -->
<template>
    <div class="zt-page-content">
        <div style="min-width:1200px;height:100%;overflow-y:hidden;">
            <div class="zt-block flex flex-v" style="height:calc(100% - 40px);padding:20px 20px 0 20px;">
                <div class="head flex">
                    <div class="select-wrap flex">
                        <label>交货日期</label>
                        <el-date-picker @change="order_time"
                            style="width:280px"
                            v-model="timeValue"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="select-wrap flex">
                        <label>订单状态</label>
                        <el-select v-model="orderState" @change="order_state" :popper-append-to-body="false" placeholder="全部">
                            <el-option v-for="item in options" :key="item.id"
                            :label="item.value" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="select-wrap flex">
                        <label>物流状态</label>
                        <el-select value="" placeholder="请选择">
                            <el-option value="1">1</el-option>
                        </el-select>
                    </div> -->
                    <div class="select-wrap flex-1 flex">
                        <div class="flex-1"></div>
                        <div style="width: 270px">
                            <el-input
                            v-model="searchValue" @change="search" 
                            placeholder="商品名称、订单编码、对方公司" 
                            prefix-icon="el-icon-search"/>
                        </div>
                    </div>
                </div>
                <div class="thead">
                    <el-row>
                        <el-col :span="5">商品名称</el-col>
                        <el-col :span="5">条形编码</el-col>
                        <el-col :span="4">交货数量</el-col>
                        <el-col :span="4">商品总价</el-col>
                        <el-col :span="3">物流状态</el-col>
                        <el-col :span="2">操作</el-col>
                    </el-row>
                </div>
                <div style="overflow-y:auto;" class="table flex-1" ref="load">
                    
                    <div class="tbody">
                        <div v-for="(item,idx) in list" :key="idx" class="order-block">
                            <div class="order-head flex">
                                <div class="flex-1">交易日期：{{item.createdAt | moment("YYYY/MM/DD HH:mm:ss")}}</div>
                                <div class="flex-1">订单编码：{{item.code}}</div>
                                <div class="flex-1">订单状态：
                                    <span v-if="item.state==1" style="color:#F66F6A;">待工厂确认订单</span>
                                    <span @click="order_pay(item)" v-if="item.state<5 && item.state>1" 
                                        style="color:#016FFF;cursor:pointer">待付款</span>
                                    <span v-if="item.state==5" style="color:#F66F6A;">待工厂确认付款</span>
                                    <span v-if="item.state==6" style="color:#F66F6A;">交易完成</span>
                                </div>
                                <div class="flex-1 ellipsis">{{item.factory.name}}</div>
                                <div class="flex-1">订单来源：
                                    <span v-if="item && item.src=='fx'">分销订单</span>
                                    <span v-else>集市</span>
                                </div>
                            </div>
                            <div class="order-body">
                                <el-row v-for="(item1,idx1) in item.entries" :key="idx1">
                                    <el-col :span="5">
                                        <a @click="gotoDetail(item1.item)" class="ellipsis">{{item1.item.name}}</a>
                                    </el-col>
                                    <el-col :span="5">{{item1.itemSku.code}}</el-col>
                                    <el-col :span="4">{{item1.count}}</el-col>
                                    <el-col :span="4"><span>￥{{item1.amount | priceFilt}}</span></el-col>
                                    <el-col :span="3">———</el-col>
                                    <el-col :span="2"></el-col>
                                </el-row>
                                
                            </div>
                            <div class="order-foot flex">
                                <!-- <div class="flex-1 ellipsis">售后：售后内容售后内容</div>
                                <div class="flex-1">运费：￥128.00</div> -->
                                <div style="text-align:right;" class="flex-1">
                                    <!-- <el-button @click="add_logistics('填写物流信息',item)" size="small" type="text">填写物流信息</el-button> -->
                                    <el-button v-if="item.state<5 && item.state>1" @click="order_pay(item)" size="small" type="text">付款</el-button>
                                    <!-- <el-button v-if="item.state==1" @click="edit_order(item)" size="small" type="text">编辑</el-button>
                                    <el-button v-if="item.state==1" @click="del_order(item)" size="small" type="text">删除</el-button> -->
                                    <el-button @click="order_detail(item)" size="small" type="text">订单详情</el-button>
                                    <el-button v-if="item.state==5 || item.state==6" 
                                        @click="view_pay(item)" size="small" type="text">查看付款记录</el-button>
                                    <!-- <el-button v-if="item.state==4" @click="qr_order(item)" size="small" type="text">确认收货</el-button> -->
                                    <el-button v-if="item.state==99" style="color:#4D4D4D;" size="small" type="text">已收货</el-button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div v-if="pageShow" style="padding:15px 0;text-align:center;">
                    <el-pagination background layout="prev, pager, next" 
                        @current-change="currentChange"
                        :current-page.sync="queryOpt.page.pageNumber" 
                        :page-size="queryOpt.page.pageCount" 
                        :total="total">
                    </el-pagination>
                </div>

            </div>
        </div>

        <el-dialog width="1200px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditLogistics />
        </el-dialog>
    </div>
</template>

<script>
import { IMG_URL_PRE } from '@/config';
import { payGetUrl,getPayId } from '@/service/pay';
import { listBuyPurOrder,payPurOrder,delPurOrder } from '@/service/sale';
import EditLogistics from "./EditLogistics"

export default {
    components: {
        EditLogistics
    },
    data() {
        return {
            imgUrl:IMG_URL_PRE,
            popVisible: false,
            popTitle: '',
            queryOpt:{
                page:{
                    pageNumber:1,
                    pageCount:15,
                },
                where:{
                    key:null,
                    state:null,
                    createdAt:{
                        start:null,
                        end:null,
                    }
                },
                order: [
                    ["createdAt","desc"]
                ],
            },
            total:0,
            pageShow:false, // 强制刷新分页组件
            list:[],
            // 订单状态
            options: [
                {id: 0,value: '全部'},
                {id: 1,value: '待付款'},
                {id: 2,value: '待对方确认'},
                {id: 3,value: '交易完成'},
            ],
            orderState:0,
            timeValue:"",
            searchValue:null,
            ruleForm: {
                warehouseName: "",//仓库名称
                totalCount: 1000,//货品总件数
                cebxsi: "0",//报关方式
                exportCity: "洛杉矶",//出口城市
                deliveryDate: "2021-06-23T08:22:08.971Z",//送货日期
                mailingMethod: 1,// 寄件方式（1为上门提货，2为自己送货）
                transportCategory: 1,// 配送方式（0为城配，1为1物流）
                ifAcceptOrder: 0,// 是否接受拼单（0为否，1为是）
                paymentMethod: "0",// 付款方式（0为月结，1为现付）
                remark: "",//备注（限200字）
                installationMethod: 0,// 安装方式（0为自己安装，1为预约安装）
                deliveryMethod: "0", // （0是无，1是自提，2为上门）// 送货方式
                customerName: "美观",// 收件名称
                customerAddress: "洛杉矶",// 收件人地址
                customerContactsPhone: "18888888888",// 收件人联系电话
                customerContacts: "奥巴马",// 收件人姓名
                enterpriseName: "张大",// 寄件名称
                enterpriseAdress: "北京",// 寄件地址
                enterpriseDirector: "郑辉",// 寄件人名称
                enterpriseDirectorMobile: "99999999999",// 寄件人联系方式
                refUrl:"",//反推地址
                orgId:1,
                TenantId:0,
                details: [
                    {
                        goodsCode: "10002-222-33-4",//物品类别Code
                        goodsName: "床",//物品名称
                        specifications: "2200x2000",//规格型号
                        unit: "",//计量单位
                        goodsType: "大床",//型号
                        color: "白色",//颜色
                        size: "",//尺寸（长宽高）组合
                        number: 1000,//数量
                    }
                ]
            }
        }
    },
    created() {},
    mounted() {
        if (this.$route.query.page && this.$route.query.page>1) {
            if (this.$route.query.key) {
                this.queryOpt.where.key = this.$route.query.key;
                this.searchValue = this.queryOpt.where.key;
            }
            this.queryOpt.page.pageNumber=parseInt(this.$route.query.page);
            this.refreshItems();
        } else {
            if (this.$route.query.key) {
                this.queryOpt.where.key = this.$route.query.key;
                this.searchValue = this.queryOpt.where.key;
            }
            this.refreshItems();
        }
        this.$nextTick(()=>{
            this.pageShow = true;
        })
    },
    methods: {
        goto(path){
            this.$router.push(path);
        },
        gotoDetail(item) {
            console.log(item);
            window.open("/sale/mall/home/factory_detail/"+item.id);
            // if (item.priceType==2) {
            //     window.open("/sale/mall/home/factory_detail/"+item.id);
            // } else {
            //     window.open("/sale/mall/home/design_detail/"+item.id);
            // }
        },
        refresh() {
            this.queryOpt.page.pageNumber = 1;
            if (this.queryOpt.page.pageNumber != this.$route.query.page) {
                this.$router.push({
                    query:{ page:1, key: this.searchValue}
                });
            }
            this.pageShow = false;
            this.refreshItems();
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        order_time(e) {
            console.log(e);
            if (e) {
                this.queryOpt.where.createdAt.start = new Date(e[0]).getTime();
                this.queryOpt.where.createdAt.end = new Date(e[1]).getTime();
            } else {
                this.queryOpt.where.createdAt.start = null;
                this.queryOpt.where.createdAt.end = null;
            }
            this.refresh();
        },
        //搜索条件改变时触发的事件
        search(e) {
            this.queryOpt.where.key = e;
            this.refresh();
        },
        order_state(event) {
            if (event == 0) {
                this.queryOpt.where.state = null;
            } else if (event == 1) {
                this.queryOpt.where.state = [0,1,2,3,4];
            } else if (event == 2) {
                this.queryOpt.where.state = [5];
            } else if (event == 3) {
                this.queryOpt.where.state = [6];
            }
            this.refresh();
        },
        currentChange(e) {
            if (this.queryOpt.where.key) {
                this.searchValue = this.queryOpt.where.key;
                this.$router.push({
                    query:{ page:e, key: this.queryOpt.where.key}
                });
            } else {
                this.$router.push({
                    query:{ page:e }
                });
            }
            this.refreshItems();
        },
        refreshItems() {
            console.log('refreshItems');
            this.list = [];
            const _loading = this.$loading({
                target:this.$refs.load,
                lock: true,
                text: "拼命加载中....",
            });
            listBuyPurOrder(this.queryOpt).then(rst => {
                if (rst && rst.rows && rst.rows.length>0) {
                    this.total=rst.count;
                    this.list = rst.rows;
                } else {
                    this.total=0;
                    this.list = [];
                }
                console.log(this.list);
                _loading.close();
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
                _loading.close();
            });
        },
        order_detail(item) {
            this.$router.push("/sale/pur/cgdd/detail/"+item.id);
        },
        // 查看付款记录
        view_pay(item) {
            this.$router.push({
                path:"/pay/payList",
                query:{
                    order_id:item.tradeOrderId,
                }
            });
        },
        // 去支付
        async order_pay(item) {
        //     let trans_id = await getTransId(item.tradeOrderId);
            this.$router.push({
                path:"/pay/waitPay",
                query:{
                    type:0,
                    oType:"pur_order",
                    order_id:item.tradeOrderId,
                    comp_id:item.factory.id,
                }
            });
        },
        add_logistics(text,item) {
            this.popTitle = text;
            this.popVisible = true;

        }
    }
}
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped>
.select-wrap>>> .el-date-editor .el-range-separator {
    width: 10%;
}
</style>
<style scoped lang="less">
.block {
    padding: 13px 32px 63px;
    color: #808080;
}
.head {
    width: 100%;
    margin-bottom: 19px;
    .tabs {
        display: flex;
        align-items: center;
        span {
            width: 2px;
            height: 50px;
            background-color: #858585;
            margin: 0 16px;
        }
        .tab {
            width:128px;
            height:42px;
            border-radius: 21px;
            background:#fff;
            opacity:1;
            border-radius:21px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #4D4D4D;
            cursor: pointer;
            font-size: 20px;
            &.active {
                color: #fff;
                background-color: #24B1EB;
            }
        }
    }
    .select-wrap {
        font-size: 16px;
        align-items: center;
        margin-right: 50px;
        label {
            margin-right: 16px;
        }
    }
}
.thead {
    background:rgba(245,245,245,1);
    box-shadow:0px 3px 6px rgba(0,0,0,0.16);
    font-size: 16px;
    font-weight: bold;
    .el-col {
        height: 60px;
        text-align: center;
        line-height: 60px;
    }
}
.table {
    
    .tbody {
        .order-block {
            margin-top: 16px;
            box-shadow:0px 3px 6px rgba(0,0,0,0.16);
            border: 1px solid #E6E6E6;
            .order-head {
                height: 40px;
                line-height: 40px;
                padding-left: 15px;
                background-color: #F5F5F5;
                // display: flex;
                position: relative;
                text-align: center;
                >div:last-child {
                    text-align: center;
                }
                a {
                    position: absolute;
                    right: 40px;
                }
            }
            .order-body {
                .el-col {
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    span {
                        color: #EE1818;
                    }
                }
            }
            .order-foot {
                height: 40px;
                line-height: 40px;
                padding:0 15px;
                border-top: 1px solid #E6E6E6;
                // display: flex;
                position: relative;
                text-align: center;
                >div:last-child {
                    text-align: center;
                }
                a {
                    position: absolute;
                    right: 40px;
                }
            }
        }
    }
}
</style>